export const Themes = {
  classic: {
    "text-base": "black",
    "text-inverted": "white",
    "text-alt": "gray",
    "text-info": "#202020",
    "text-highlight": "blue",
    "text-selected": "green",
    "text-important": "orange",

    "background-primary": "white",
    "background-alt": "black",
    "background-card": "dodgerblue",
    "background-panel": "#aaa",

    "button-base": "blue",
    "button-delete": "indianred",
    "button-hover": "#aaa",
    "button-action": "white",
    "button-success": "green",
    "button-flat": "gray",
    "button-flat-hover": "#404040",

    "alert-danger": "indianred",
    "alert-info": "yellow",
  },
  moonlit: {
    "text-base": "white",
    "text-alt": "gray", //primary
    "text-highlight": "blue", //primary

    "button-base": "blue", //primary
    "button-delete": "red", //primary
    "button-success": "green", //primary
    "button-flat": "gray", //primary
  },
  dawn: {
    "text-base": "black",
    "text-alt": "black", //primary
    "text-highlight": "brown", //primary

    "button-base": "brown", //primary
    "button-delete": "red", //primary
    "button-success": "green", //primary
    "button-flat": "gray", //primary
  },
};
